






























































import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import CartGradient from './CardGradient.vue'
import ColorPallets from '@/views/ProfileDetail/components/ColorPallets.vue'
import { GRADIENT_COLORS } from '@/common/constants'
import FileLoader from '@/components/FileLoader/index.vue'
import { createPGT } from '@/services/api/pgt'
import Loading from '@/components/Loading/index.vue'

export default Vue.extend({
  name: 'ModalAddPRT',

  components: {
    CartGradient,
    ColorPallets,
    FileLoader,
    Loading
  },

  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    businessName: {
      type: String as PropType<string>,
      default: ''
    },
    userId: {
      type: String as PropType<string>,
      default: ''
    }
  },

  data () {
    return {
      showed: false,
      cardColor: 'transparent',
      textColors: [
        { hex: '#ffffff', text: 'rgba(255,255,255,1)', background: 'rgba(255,255,255,0.2)' },
        { hex: '#000000', text: 'rgba(0,0,0,1);', background: 'rgba(0,0,0,0.2);' }
      ],
      gradients: GRADIENT_COLORS,
      selectedGradient: GRADIENT_COLORS[0],
      selectedTextColor: { hex: '#ffffff', text: 'rgba(255,255,255,1)', background: 'rgba(255,255,255,0.2)' },
      isShownName: true,
      loadImageShow: false,
      loading: false,
      loadedImage: null,
      image: null
    }
  },

  watch: {
    value (val) {
      this.showed = val
    }
  },

  mounted () {
    this.showed = this.value
  },

  methods: {
    ...mapActions('common', [
      'uploadMedia'
    ]),
    handleBackgroundColor (color: { from: string, to: string, hex: string }) {
      this.selectedGradient = color
    },

    handleTextColor (color: { hex: string, text: string, background: string }) {
      this.selectedTextColor = color
    },
    handleLoaderClick () {
      console.log(this.$refs.file.$refs.controller)
      this.$refs.file.$refs.controller.click()
    },
    onloadPicHandler ({ imageUrl, image }) {
      this.loadedImage = imageUrl
      this.image = image
    },
    async handleCreatePGT () {
      // send image to the server
      if (this.image) {
        const formData = new FormData()
        formData.append('file_to_upload', this.image)

        try {
          const res = await this.uploadMedia(formData)
          this.loadedImage = res.url
        } catch {
          this.$toast.error('Произошла ошибка при загрузке фотографии')
        }
      }

      // send request to the server with all data
      try {
        await createPGT({
          profileId: this.userId,
          data: {
            text_display_mode: this.isShownName,
            bg_image: this.loadedImage,
            bg_color: {
              primary: this.selectedGradient.from,
              secondary: this.selectedGradient.to
            },
            text_color: this.selectedTextColor.hex
          }
        })
        this.$toast.success('Профиль генератора трафика успешно создан')
        this.$bvModal.hide('modal-add-PRT')
      } catch {
        this.$toast.error('Произошла ошибка при создании профиля генератора трафика')
      }
    },
    onModalHidden () {
      this.$emit('modal-hide')
      this.loadedImage = null
    }
  }
})
