










































import Vue from 'vue'
import {
  mapState,
  mapMutations,
  mapActions
} from 'vuex'
import ProfileHeader from './components/ProfileHeader.vue'

export default Vue.extend({
  name: 'ProfileDetail',

  components: {
    ProfileHeader
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ]),

    breadcrumbItems () {
      return [
        {
          text: 'Главная',
          to: { name: 'home' }
        },
        {
          text: 'Бизнес-профили',
          to: { name: 'profiles-active' }
        },
        {
          text: this.profile.username,
          to: { name: 'profile-main', params: { profileId: this.$route.params.profileId } }
        },
        // {
        //   text: 'Каталоги',
        //   to: { name: 'profile-catalogs', params: { profileId: this.$route.params.profileId } }
        // },
        {
          text: this.$route.meta.title,
          active: true
        }
      ]
    }
  },

  watch: {
    $route () {
      this.setBreadcrumbItems(this.breadcrumbItems)
    }
  },

  beforeRouteUpdate (to, from, next) {
    // this.setBreadcrumbItems([])
    next()
  },

  async created () {
    this.getBusinessCategories()
    this.getBusinessFilters()
    await this.getProfileDetails()
    this.setBreadcrumbItems(this.breadcrumbItems)
  },

  beforeDestroy () {
    this.resetProfile()
    this.setBreadcrumbItems([])
  },

  methods: {
    ...mapActions('profiles', [
      'getBusinessProfile'
    ]),

    ...mapActions('common', [
      'getBusinessCategories',
      'getBusinessFilters',
      'setBreadcrumbItems'
    ]),

    ...mapMutations('profiles', [
      'resetProfile'
    ]),

    async getProfileDetails () {
      this.loading = true
      const profileResponse = await this.getBusinessProfile(this.$route.params.profileId)
      this.loading = false

      if (profileResponse.status < 200 || profileResponse.status >= 300) {
        this.$toast.error(profileResponse?.data?.detail?.message ||
          'Извините, произошла ошибка получения профиля. Попробуйте попозже.')
      }
    },

    handleArchive () {
      this.$bvModal.msgBoxConfirm('Это действие нельзя отменить', {
        title: 'Вы действительно хотите архивировать этот бизнес профиль?',
        size: 'sm',
        // okVariant: 'danger',
        okTitle: 'Архивировать',
        cancelTitle: 'Отмена',
        footerClass: 'p-2 justify-content-center',
        // hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          console.log('архивируем')
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
})
