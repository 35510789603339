










import Vue from 'vue'
import { RectangleStencil, Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

export default Vue.extend({
  name: 'file-loader',

  components: {
    RectangleStencil,
    Cropper
  },

  data () {
    return {
      image: {
        src: null,
        type: null
      }
    }
  },

  methods: {
    defaultSize ({ imageSize, visibleArea }) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
      }
    },
    getMimeType (file, fallback = null) {
      const byteArray = (new Uint8Array(file)).subarray(0, 4)
      let header = ''

      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16)
      }

      switch (header) {
        case '89504e47':
          return 'image/png'
        case '47494638':
          return 'image/gif'
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
          return 'image/jpeg'
        default:
          return fallback
      }
    },
    handleFileUpload () {
      this.image = this.$refs.controller.files[0]
      this.imageUrl = URL.createObjectURL(this.image)
      const localImageUrl = this.imageUrl
      this.$emit('onload', { imageUrl: this.imageUrl, image: this.image })
      return { imageUrl: this.imageUrl, localImageUrl: localImageUrl }
    }
  }

})
