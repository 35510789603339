
















































import Vue, { PropType } from 'vue'
import {
  mapState,
  mapActions
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import Avatar from '@/components/Avatar.vue'// './ProfileAvatar.vue'
import ModalMadePGD from '@/components/ModalProfileTrafficGenerator/index.vue'
import { S3_URL } from '@/common/constants'

export default Vue.extend({
  name: 'ProfileHeader',

  components: {
    Loading,
    Avatar,
    ModalMadePGD
  },

  props: {
    actionTitle: {
      type: String as PropType<string>,
      default: null
    },

    actionBtnVariant: {
      type: String as PropType<string>,
      default: 'secondary'
    }
  },

  data () {
    return {
      defaultAvatarUrl: `${S3_URL}/default_avatar.png`,
      modalShow: false
    }
  },

  computed: {
    ...mapState('profiles', [
      'profile'
    ])
  },

  methods: {
    ...mapActions('profiles', [
      'updateClientProfile',
      'updateBusinessProfile'
    ]),

    async handleDeleteAvatar () {
      const updatedProfile = this.profile
      const updateMethod = this.profile.profile_type === 'client' ? 'updateClientProfile' : 'updateBusinessProfile'

      updatedProfile.avatar_url = this.defaultAvatarUrl

      try {
        this.saving = true
        await this[updateMethod](updatedProfile)
        this.$toast.success('Аватар удалён')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    },

    async handleUploadedAvatar (url: string) {
      const updatedProfile = this.profile
      const updateMethod = this.profile.profile_type === 'client' ? 'updateClientProfile' : 'updateBusinessProfile'

      updatedProfile.avatar_url = url

      try {
        this.saving = true
        await this[updateMethod](updatedProfile)
        this.$toast.success('Аватар обновлен')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.saving = false
      }
    }
  }
})
