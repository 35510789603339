
















import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'CardGradient',

  props: {
    title: {
      type: String as PropType<string>,
      default: ''
    },
    gradient: {
      type: String as PropType<string>,
      default: ''
    },
    image: {
      type: String as PropType<string>,
      default: ''
    },
    colors: {
      type: Object as PropType<{hex: string, background: string, text: string}>,
      default: () => {}
    }
  }
})
